import styles from './Contact.module.css';
import employees_01 from '../../img/employees01.webp';
import employees_02 from '../../img/employees02.webp';
import { useState } from 'react';
import classNames from 'classnames';

export default function Contact () {

  const [changeBg, setChangeBg] = useState(false)

  return <footer className={classNames(styles.footer, changeBg && styles.top_img)} id="contact-id">
    {changeBg ? <img src={employees_02} alt="Employees of Invite" /> : <img src={employees_01} alt="Employees of Invite" />}
    
    
    <div className={styles.info} onMouseEnter={() => setChangeBg(true)} onMouseLeave={() => setChangeBg(false)}>
      <h2>Contact</h2>
      <p>
        <span className={styles.icon}>
          <i className="fas fa-user-headset"></i> 
        </span>
        <span>+46 (0) 46-525 80</span>
      </p>

      <p>
        <span className={styles.icon}>
          <i className="fal fa-envelope"></i>
        </span>
        
        <a href="mailto:support@cupmanager.net">support@cupmanager.net</a>
      </p>
    </div>
  </footer>
}