import styles from './HeroTop.module.css';
import logoText from '../../img/logo_text.svg';
import logoText_col from '../../img/logo_text_col.svg';
import logoPunch from '../../img/logo_punch.svg';
import line01 from '../../img/line_01.svg';
import line02 from '../../img/line_02.svg';
import line03 from '../../img/line_03.svg';
import line04 from '../../img/line_04.svg';
import line05 from '../../img/line_05.svg';
import line06 from '../../img/line_06.svg';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { useRef, useState } from 'react';

const scrollToDiv = (hash: string) => {
  if(navigator.vendor ===  "Apple Computer, Inc." /* CATCH ALL SAFARI BRWSERS */) {
    const url = window.location;
    url.hash = hash;
    document.location.href = url.href;
  }

  const divElement = document.getElementById('invite-id');
  divElement && divElement.scrollIntoView({ behavior: 'smooth' });
}

export default function HeroTop () {

  const [linesIn, setLinesIn] = useState(false);
  const [arrowIn, setArrowIn] = useState(false);

  setTimeout(function(){
    setLinesIn(true);
  }, 2000);

  setTimeout(function(){
    setArrowIn(true);
  }, 4000);

  const nodeRef = useRef(null);


  return <div id="top-id">
      <header className={styles.hero}>
      
      <CSSTransition 
        in={linesIn}
        unmountOnExit
        timeout={3000} 
        nodeRef={nodeRef}
        classNames={{
          enter: styles.linesEnter,
          enterActive: styles.linesEnterActive,
          exit: styles.linesExit,
          exitActive: styles.linesExitActive
        }}
      >
        <div className={styles.linesTop} ref={nodeRef}>
          <img src={line01} className={classNames(styles.line, styles.line1)} alt=""/>
          <img src={line02} className={classNames(styles.line, styles.line2)} alt=""/>
        </div>
      </CSSTransition>

      <h1 className={styles.logo} aria-label="Invite - Simplifies Sport">
        <picture>
          <source media="(min-width: 600px)" srcSet={logoText} />
          <img src={logoText_col} alt="" className={styles.logoText}/>
        </picture>
        <div>
          <img src={logoPunch} alt="" className={styles.logoPunch}/>
        </div>
      </h1>

      <CSSTransition 
        in={linesIn}
        unmountOnExit
        timeout={10000}
        nodeRef={nodeRef}
        classNames={{
          enter: styles.linesEnter,
          enterActive: styles.linesEnterActive,
          exit: styles.linesExit,
          exitActive: styles.linesExitActive
        }}
      >

        <div className={styles.linesBottom} ref={nodeRef}>
          <img src={line03} className={classNames(styles.line, styles.line3)} alt=""/>
          <img src={line04} className={classNames(styles.line, styles.line4)} alt=""/>
          <img src={line05} className={classNames(styles.line, styles.line5)} alt=""/>
          <img src={line06} className={classNames(styles.line, styles.line6)} alt=""/>
        </div>
      </CSSTransition>

      <CSSTransition 
        in={arrowIn}
        unmountOnExit
        timeout={1000} 
        nodeRef={nodeRef}
        classNames={{
          enter: styles.arrowEnter,
          enterActive: styles.arrowEnterActive,
          exit: styles.arrowExit,
          exitActive: styles.arrowExitActive
        }}
      >
        <button ref={nodeRef} className={styles.arrow} onClick={() => scrollToDiv('#invite')}>
          <i className="fal fa-arrow-circle-down"></i>
        </button>
      </CSSTransition>
    </header>
  </div>
}