import styles from './Mother.module.css';
import logo_cupmanager from '../../img/logo_cupmanager.svg';
import logo_superinvite from '../../img/logo_superinvite.svg';
import logo_cupinsight from '../../img/logo_cupinsight.svg';
import logo_cupinvite from '../../img/logo_cupinvite.svg';

export default function Main () {

  return <div className={styles.children} id="invite-id">
    <div className={styles.innerWrapper}>
      <div className={styles.info}>
        <h2>Invite Sports - Simplified</h2>

        <p>Invite Sports is a Scandinavian company dedicated to smart and efficient IT solutions for sports clubs and sports related activities. We cover a wide range of needs, all the way from payment solutions and state-of-the-art tournament software and a tournament portal. </p>
        <p>Our solutions are presently used in more than 20 countries across the world by more than 1000 sports clubs.</p>
      </div>

      <div className={styles.logos}>
        <span>
          <img src={logo_superinvite} alt="" className={styles.superInvite} />
        </span>
        <span>
          <img src={logo_cupmanager} alt="" className={styles.cupManager} />
        </span>
        <span>
          <img src={logo_cupinsight} alt="" className={styles.cupinsight} />
        </span>
        <span>
          <img src={logo_cupinvite} alt="" className={styles.cupInvite} />
        </span>
      </div>
    </div>
  </div>
}