import classNames from 'classnames';
import styles from './App.module.css';
import HeroTop from './components/HeroTop/HeroTop';
import Mother from './components/Mother/Mother';
import Children from './components/Children/Children';
import main_bg from './img/main_bg.gif';
import ChildrenPartTwo from './components/Children/ChildrenPartTwo';
import Contact from './components/Contact/Contact';
import Navigation from './components/Navigation/Navigation';
import { useEffect, useState } from 'react';
import Jobs from './components/Jobs/Jobs';
import { Route, Routes } from 'react-router-dom';
import CustomerServiceWorker from './components/JobPosition/CustomerServiceWorker';
import CountrySalesManager from './components/JobPosition/CountrySalesManager';
import Employees from './components/Children/Employees';
import FullstackDeveloper from './components/JobPosition/FullstackDeveloper';


function App() {

  useEffect(() => {
    const cssAppHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }

    window.addEventListener('resize', cssAppHeight)
    cssAppHeight();
    
  }, [])

  const [modalVisible, setModalVisible] = useState(false)

  return (
    <div className={classNames("App", styles.App)} style={{backgroundImage: `url(${main_bg})`}} id="app">
      <Routes>
        <Route path="/" element={<>
          <Navigation visible={!!modalVisible}/>
          <HeroTop/>
          <main className={styles.main}>
            <Mother/>
            <Children/>
            <ChildrenPartTwo/>
            {/* <Jobs/> */}
            <Employees setModalVisible={setModalVisible} />
          </main>
          <Contact/>
        </>} />
        <Route path={'/customer-service-worker'} element={<>
          <CustomerServiceWorker />
        </>}/>

        <Route path={'/fullstack-developer'} 
        element={<>
          <FullstackDeveloper />
        </>}/>

        <Route path={'/country-sales-manager'} element={<>
          <CountrySalesManager />
        </>}/>
      </Routes>
    </div>
  );
}

export default App;
