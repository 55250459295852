import styles from './Children.module.css';
import cupinsight from '../../img/cupinsight.webp';
import ci from '../../img/ci.jpg';
import logo_cupinsight from '../../img/logo_cupinsight.svg';
import logo_cupinvite from '../../img/logo_cupinvite.svg';
import MyLink from '../MyLink/MyLink';
import classNames from 'classnames';

export default function ChildrenPartTwo () {
  return <div className={styles.children} id="children02-id">
    <div className={styles.spotLine}>
      <div className={styles.backgroundContainer}>
        <div className={styles.background} style={{backgroundImage: `url(${cupinsight})`}}>
        </div>
      </div>
      <div className={styles.aboutChildren}>
        <article>
          <h3>
            <img src={logo_cupinsight} alt="CupInsight" className={styles.logo}/>
          </h3>
          <p>CupInsight is designed for our tournament organizers. Our goal with CupInsight is to provide our organizers with valuable insights and tools to elevate tournaments to new heights. Whether it’s enhancing quality or increasing scale, CupInsight helps to achieve these goals. CupInsight consists of two annual components: a report and a conference. These components will empower our tournament organizers to take their tournaments to the next level.</p>
          <MyLink url="https://cupinsight.cups.nu/" text="cupinsight.cups.nu" />
        </article>
      </div>
    </div>
    <div className={styles.spotLine}>
      <div className={styles.aboutChildren}>
        <article>
          <h3>
            <img src={logo_cupinvite} alt="Cup Invite" className={classNames(styles.logo, styles.cupinvite)}/>
          </h3>
          <p>Cupinvite.com aggregates all the tournaments that use our solutions and advertise them to potential customers. The portal is completely automatic and sources its data from both our internal systems, as well as automatic evaluations that are sent to all Cup Manager tournaments.</p>
          <MyLink url="https://cupinvite.com/" text="CupInvite.com" />
        </article>
      </div>
      <div className={styles.backgroundContainer}>
        <div className={styles.background} style={{backgroundImage: `url(${ci})`}}>
        </div>
      </div>
    </div>
  </div>
}