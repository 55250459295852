import classNames from 'classnames';
import styles from './Navigation.module.css';
import { CSSTransition } from 'react-transition-group';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';


export default function Navigation ({visible} : {visible: boolean}) {

  let options = {
    rootMargin: '0px',
    threshold: 0.5
  }

  const isSafari = navigator.vendor ===  "Apple Computer, Inc.";
  
  let observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if(entry.isIntersecting && entry.intersectionRatio < 0.9) {
        const elId = entry.target.id
        const hash = elId.substring(0, elId.length - 3);
        setUrlNoScroll(hash)
      }

      if(entry.isIntersecting && isSafari) {
        const elId = entry.target.id
        const hash = elId.substring(0, elId.length - 3);
        setUrlNoScroll(hash)
      }

    })
  }, options);
  
  
  const setUrlNoScroll = (hash: string) => {
    const url = window.location;
    url.hash = hash;
    document.location.href = url.href;
  
  }
  
  const setUrl = (hash: string) => {
    if(isSafari) {
      const url = window.location;
      url.hash = hash;
      document.location.href = url.href;
    }

    console.log({hash})
  
    if(hash === '#top') {
      document.getElementById('app')?.scrollTo({behavior: 'smooth', top: 0, left: 0})
    } else {
      document.getElementById(`${hash.substring(1)}-id`)?.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
  
  }
  
  const setUrlStart = (hash: string) => {
  
    if(hash === '#top') {
      document.getElementById('app')?.scrollTo({behavior: 'smooth', top: 0, left: 0})
    } else {
      console.log(hash);
      setTimeout(()=> document.getElementById(`${hash.substring(1)}-id`)?.scrollIntoView({behavior: 'smooth', block: 'start'}),101);
    }
  }

  const location = useLocation();

  useEffect(() => {
      const hash = window.location.hash;

      observer.observe(document.getElementById('top-id') as HTMLElement);
      /* observer.observe(document.getElementById('jobs-id') as HTMLElement); */
      observer.observe(document.getElementById('invite-id') as HTMLElement);
      observer.observe(document.getElementById('children01-id') as HTMLElement);
      observer.observe(document.getElementById('children02-id') as HTMLElement);
      observer.observe(document.getElementById('employees-id') as HTMLElement);
      observer.observe(document.getElementById('contact-id') as HTMLElement);
      
      if(hash.length > 0) { 
        setUrlStart(hash);
      }

      const appElement = document.getElementById('app');

      if (window.matchMedia('(pointer: coarse)').matches) {
        setTimeout(() => {
          if(appElement) appElement.style.scrollSnapType="y mandatory"
        }, 100)
      }
  }, [])


  const [arrowIn, setArrowIn] = useState(false);

  const navigationPosition = (hash:string) => {
    if(hash === '#contact' || location.hash === '#top' || location.hash === '' || hash === '#invite' || hash === '#jobs' || hash === '#employees') {
      return false;
    }
    return true;
  }

  const navigationColor = (hash:string) => {
    if(hash === '#contact' || location.hash === '#top' ||  location.hash === '') {
      return false;
    }
    return true;
  }


  const timeOutOnNav = window.location.hash.length === 0 
    ? 4000 
    : 800
  
  setTimeout(function(){
    setArrowIn(true);
  }, timeOutOnNav);

  const nodeRef = useRef(null);

  return <CSSTransition
    in={arrowIn}
    unmountOnExit
    timeout={1000}
    nodeRef={nodeRef}
  >
  <nav ref={nodeRef} className={classNames(
    styles.navigation, 
    navigationPosition(location.hash) && styles.top
    )
  } style={{
    '--nav-color' : navigationColor(location.hash) ? 'black' : 'white',
    '--nav-text-color': navigationColor(location.hash) ? 'white' : 'black',
    zIndex: visible ? '-1' : '1'
  } as any}>
    <NavLink navId="#top" text="Top" setUrl={(hash) => {
      setUrl(hash);
    }}/>
    
    <div className={styles.lineBetween}>
      <div></div>
    </div>

    <NavLink navId="#invite" text="Invite" setUrl={(hash) => {
      setUrl(hash);
    }}/>
    
    <div className={styles.lineBetween}>
      <div></div>
    </div>

    <NavLink navId="#children01" text="SuperInvite & CupManager" setUrl={(hash) => {
      setUrl(hash);
    }}/>
    
    <div className={styles.lineBetween}>
      <div></div>
    </div>

    <NavLink navId="#children02" text="CupInsight & CupInvite" setUrl={(hash) => {
      setUrl(hash);
    }}/>

    <div className={styles.lineBetween}>
      <div></div>
    </div>

    {/* <NavLink navId="#jobs" text="Open job positions" setUrl={(hash) => {
      setUrl(hash);
    }}/>  
    
    <div className={styles.lineBetween}>
      <div></div>
    </div>*/}

    <NavLink navId="#employees" text="Employees" setUrl={(hash) => {
      setUrl(hash);
    }}/>
    
    <div className={styles.lineBetween}>
      <div></div>
    </div>

    <NavLink navId="#contact" text="Contact" setUrl={(hash) => {
      setUrl(hash);
    }}/>
  </nav>
  </CSSTransition>
}

/* ******************************************************************************* */

type props = {
  navId: string,
  text:string,
  setUrl: (hash:string) => void
}

const NavLink = ({navId, text, setUrl} : props) => {
  const location = useLocation();

  const getIcon = location.hash !== navId
  ? <>
    <div className={classNames(styles.navRing)}></div>
  </>
  : <>
    <div className={classNames(styles.navRing, styles.selected)}></div>
  </>

  return <div className={classNames(styles.link)}>
    <span className={styles.linkTextContainer}>
      <span className={styles.menuText}>
        {text}
      </span>
    </span>
    <button 
      onClick={() => setUrl(navId)} 
      className={styles.btn}
    >
      {getIcon}
    </button>

  </div>
}