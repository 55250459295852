import styles from './CustomerServiceWorker.module.css';

import cm01_img from '../../img/cup_manager_01_variant.webp';
import cm02_img from '../../img/cup_manager_02_variant.webp';
import cm03_img from '../../img/cup_manager_03_variant.webp';
import cm04_img from '../../img/cup_manager_04_variant.webp';
import JobPosHead from '../JobPosHead/JobPosHead';

export default function FullstackDeveloper () {
  

  return <>
  <JobPosHead />
  <div className={styles.children} id="invite-id">
    <div className={styles.innerWrapper}>
      <div className={styles.info}>
        <span className={styles.date}>Publicerat: 2024-04-03</span>

        <h1>Senior fullstackutvecklare till växande sportstech-bolag</h1>

        <p className={styles.preamble}>
            <i>Invite Sports</i> bygger de bästa lösningarna för idrottsturneringar och events. 
            Hos oss får du möjlighet att ta idéer till verklighet och lösa problem för riktiga människor. 
            Som ett litet team har vi minimalt med byråkrati och är pragmatiska med tekniska lösningar. 
            </p>
            <p className={styles.preamble}>
            Med hjälp av vårt system <i>Cup Manager</i> organiseras 194 000 matcher mellan över 88 000 lag varje år i Nordens största och bästa turneringar för barn och ungdomar. Nu behöver vi bli fler för att bygga mer!</p>

        <img src={cm01_img} alt="" className={styles.img01} /><h2>Om Cup Manager</h2>
        
        <p>Cup Manager används för att administrera allt kring en idrottsturnering. 
          Det består dels av ett verktyg för administratörer i form av ett desktop-program som vi arbetar med att göra webbaserat. Mot lagen som deltar finns ett webbaserat system för att anmäla sig, betala, redigera sin anmälan och boka övernattning.
          </p><p>
          Det inkluderar även ett eget hemsidesystem för våra kunder (t.ex. <a target="_blank" href="https://piteasummergames.se/">Piteå Summer Games</a>) som integrerar med Cup Manager-systemet.
          Som en del av hemsidan har vi även resultatsidor där besökarna kan följa alla matcher (t.ex. <a target="_blank"  href="https://eskilscupen.nu/2023/result/">Eskilscupen</a>).
          </p>
          <p>
            Vi erbjuder också både hårdvara och mobilappar för resultatrapportering, samt white-label appar för turneringarnas besökare. 
          </p>
          <p>I tillägg gör vi också en portal för att hitta turneringar, <a target="_blank" href="https://CupInvite.com/">CupInvite.com</a></p>

        <img src={cm03_img} alt="" className={styles.img03} />

        <h2>Om utvecklingsteamet på Invite Sports</h2>

        <p>Vi är ett litet team som arbetar med att utveckla våra system Cup Manager och Superinvite. All utveckling sker inhouse på plats i Lund. Vi arbetar nära kunden och har snabba processer som gör att man ser resultatet av sitt arbete ibland redan samma dag. Eftersom halva ägargruppen sitter i utvecklingsteamet är beslutsvägarna väldigt korta.</p>

        <p>På kontoret sitter även vår supportavdelning som vi är i nära dialog med.</p>


        <h2>Vår teknikstack</h2>
        <ul>
          <li>PostgreSQL med en hel del procedurer och triggrar.</li>
          <li>Java-backend
            <ul>
              <li>Jetty som webserver, Jersey/JAX:RS för API:er</li>
              <li>XSL:FO/XSLT för utskrifter</li>
              <li>Constraint programmering för schemaläggning och annat</li>
              <li>Velocity templates för server-side generering</li>
              <li>Egenbyggd databas-replikering</li>
            </ul>
          </li>
          <li>Java-desktop-klient byggd med SWT/JFace</li>
          <li>Hosting på egna VPS:er med Ubuntu
            <ul>
              
              <li>Haproxy för lastbalansering</li>
              <li>Squid som reverse-proxy på webservrar</li>
              <li>Eget system för deployments</li>
            </ul>
          </li>
          <li>Jenkins som byggserver</li>
          <li>GitHub för versionshantering</li>
          <li>React+TS för all nyutveckling på frontend
            <ul>
              <li>Egenbyggt ramverk för data fetching</li>
            </ul>
          </li>
          <li>AngularJS (1.x) / Ionic som legacy-ramverk för vissa saker</li>
        </ul>

        <p>Våra system fungerar som navet i turneringarnas och föreningarnas verksamhet, och integrerar därför mot flera andra system. Till exempel betalningslösning (Stripe), livestreaming med AI-kameror (SolidSport), bokföringsystem och Google Play/Appstore.</p>

        <img src={cm02_img} alt="" className={styles.img02} />
        <h2>Huvudsakliga arbetsuppgifter</h2>
        <ul>
          <li>Självständigt utveckla små vertikala lösningar som löser ett specifikt problem som skär genom hela vår teknikstack</li>
          <li>I grupp bygga nya produkter/lösningar i lite längre projekt</li>
          <li>Ansvara för felsökning och buggfixning av de funktioner man utvecklar</li>
          <li>Delta i diskussioner om teknikval, arkitektur, algoritmer</li>
        </ul>
        
        <p>
          Vi söker någon som kan se helheten från databas till backend till frontend. En del av arbetet innebär också att felsöka och förbättra legacy-kod.
        </p>


        <h2>För att passa hos oss</h2>
        <p>Vi är ett socialt gäng som har en hel del kul på arbetet. Vi trivs bäst med att jobba tillsammans på kontoret och hoppas att du också gör det. 
          Här har vi en kreativ miljö där vi tillsammans diskuterar, brainstormar och löser problem som uppstår. 
          Men behöver du jobba hemifrån någon gång ibland så går det självklart bra.
        </p>
        <p>
          Du känner dig bekväm med att självständigt kunna ta en funktion i mål, med allt från eventuella databasförändringar, anpassning av API samt implementering i frontend.
        </p>
        <p>
          Du trivs med arbete som fokuserar på slutprodukten och kunden, framför tydliga formella strukturer och metodologier. 
          Vi gör inga* estimat. Ingen backlog. Ingen JIRA. Ingen* byråkrati och inga* möten. Vi vill inte störa dig när du programmerar.
          <br/>
          <i>* Eller ja, så lite som möjligt.</i>    
        </p>

        <h3>Du är bekväm med och gillar att jobba med</h3>
        <ul>
          <li>Java</li>
          <li>HTML, CSS, Javascript</li>
          <li>React och Typescript</li>
          <li>Att bygga och använda REST/JSON API:er.</li>
        </ul>
        <p>
          Du har flera års erfarenhet av systemutveckling med ovanstående. 
        </p>


        <h3>Hos oss kommer du också stöta på</h3>
        <ul>
          <li>PostgreSQL</li>
          <li>Linux, konfigurationsfiler, basic kommandon</li>
          <li>AngularJS (1.x)</li>
        </ul>

        <p>Du kommunicerar obehindrat på svenska och engelska i både tal och skrift</p>

        <h2>Detta kan vi erbjuda</h2>
        <ul>
          <li>Minimalt med byråkrati och möten som äter din tid och energi.</li>
          <li>Enskilt kontor eller gemensamt, om man vill.</li>
          <li>Möjlighet till inflytande över produkt och teknik.</li>
          <li>Stabilt och lönsamt företag utan riskkapital.</li>
          <li>Work-life balance - Vi vill träffa dig på kontoret, men vi kommer inte kräva att du jobbar kvällar/helger och vi är avslappnade med om du måste på läkarbesök, har sjuka barn eller något annat viktigt.</li> 
          <li>Tid och pengar till kompetensutveckling.</li>
          <li>Nära till kunder, det du gör används av många!</li>
          <li>Fräscha kontorslokaler med parkering och närhet till buss och spårvagn.</li>
          <li>Ett mysigt café strax utanför entrédörren och två riktigt bra lunchrestauranger på nära avstånd.</li>
        </ul>

        <h2>Förmåner</h2>

        <div className={styles.perks_grid}>
          <div>
            <h3>
              <span>
              <i className="fa-regular fa-wave-pulse"></i>
              </span>
              <span>Friskvårdsbidrag</span>
            </h3>
            <p>Upp till 5000 kronor kan du spendera på golf, gym, padel, klättring - eller vad du gillar att göra för att främja hälsan.</p>
          </div>
          <div>
            <h3>
              <span>
                <i className="fa-regular fa-martini-glass-citrus"></i>
              </span>
              <span>Afterwork</span>
            </h3>
            <p>En gång i månaden försöker vi bjuda på afterwork. Det kan bli middag, quiz eller någon annan rolig aktivitet.</p>
          </div>
          <div>
            <h3>
              <span>
                <i className="fa-regular fa-house-laptop"></i>
              </span>
              <span>Eget rum</span>
            </h3>
            <p>Om du vill får du ett eget rum på kontoret så att du kan stänga om dig.</p>
          </div>
          <div>
            <h3>
              <span>
              <i className="fa-regular fa-apple-whole"></i>
              </span>
              <span>Fruktkorg</span>
            </h3>
            <p>Varje måndag får vi en korg levererad med färsk frukt så att du får i dig lite extra vitaminer.</p>
          </div>
          <div className={styles.grid_polaroid}>
            <img src={cm04_img} alt="" className={styles.img04} />
          </div>
          <div>
            <h3>
              <span>
              <i className="fa-regular fa-screen-users"></i>
              </span>
              <span>Årlig kundkonferens</span>
            </h3>
            <p>Varje år samlar vi våra kunder på en konferens för att dela erfarenheter med varandra, och oss. Här får du möjlighet att direkt träffa de du hjälpt under året.</p>
          </div>
          <div>
            <h3>
              <span>
                <i className="fa-regular fa-mug-saucer"></i>
              </span>
              <span>Finkaffe</span>
            </h3>
            <p>Sandby Kafferosteri är vår leverantör av kaffe. Vi vågar nästan säga att vi brygger stans bästa kontorskaffe i vår Moccamaster.</p>
          </div>
          <div>
            <h3>
              <span>
              <i className="fa-regular fa-dog"></i>
              </span>
              <span>Ta med hunden</span>
            </h3>
            <p>
              Har du hund? Då behöver du inte tänka på hunddagis, pensionat, etc. Ta med den hit! Hundar finns här från och till.
            </p>
          </div>
        </div>

        {/*<ul>
          <li>Fruktkorg på måndagar</li>
          <li>Fussballpaus på eftermiddagarna</li>
          <li>Eget rum på kontoret</li>
          <li>Jobba hemifrån</li>
          <li>Café i samma hus och riktigt bra restauranger i närheten</li>
          <li>AW en gång i månaden</li>
          <li>Pannkakslunch på torsdagar</li>
          <li>Friskvårdsbidrag</li>
          <li>Hundvänlig arbetsplats</li>
        </ul>*/}

        <h2>Varaktighet, arbetstid</h2>
        <p>Tjänsten är en tillsvidareanställning på 100% med placering på vårt kontor vid Mobilvägen 10 i Lund. Tillträde enligt överenskommelse.</p>
        <p>Vi söker en erfaren utvecklare och lönenivån kommer att spegla detta.</p>

        <h2>Ansökan</h2>
        <p>
          Ansökan med CV, eventuellt GitHub-konto eller länkar till roliga sidoprojekt skickas till <a href="mailto:petronella@cupmanager.net">petronella@cupmanager.net</a>. Hon svarar också gärna på dina frågor.
        </p>

        <h2>Om Invite Sports</h2>
        
        <p>Invite Sports är ett spännande företag med stora tillväxtambitioner. Vi har avdelningar i Oslo och Lund med kunder runt om i världen. I produktportföljen finns bland annat Cup Manager och Superinvite - verktyg som hanterar registreringar, betalningar och rapportering till idrottsrörelsens olika behov.</p>
        <p>Cup Manager används av snart tusen idrottsturneringar i Sverige, Norge, Danmark och resten av världen för att administrera allt från anmälningar till spelschema och resultat. Superinvite är en ledande lösning i Norge för att samla in avgifter för t.ex. träningar och fotbollsläger.</p>
        <p>Vi är självfinansierade och lönsamma och har inte tagit in riskkapital. Vi tycker det är viktigt eftersom vi drivs av att göra våra produkter bättre och genom det växa på ett sunt och hållbart sätt. </p>
        
        <p>Läs mer om Invite Sports här: <a target="_blank" href="https://invitesports.com/">invitesports.com</a></p>
        <p>Läs mer om Cup Manager här: <a target="_blank" href="https://cupmanager.net/">cupmanager.net</a></p>
      </div>

      
    </div>
  </div>
  </>
}