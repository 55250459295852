import styles from './CustomerServiceWorker.module.css';

import cm01_img from '../../img/cup_manager_01_variant.webp';
import cm02_img from '../../img/cup_manager_02_variant.webp';
import cm03_img from '../../img/cup_manager_03_variant.webp';
import cm04_img from '../../img/cup_manager_04_variant.webp';
import JobPosHead from '../JobPosHead/JobPosHead';

export default function CountrySalesManager () {
  return <>
  <JobPosHead />
  <div className={styles.children} id="invite-id">
    <div className={styles.innerWrapper}>
      <div className={styles.info}>
        <span className={styles.date}>Publicerat: 2022-10-12</span>

        <h1>Invite Sports söker Country Sales Manager Sverige </h1>

        <p className={styles.preamble}>Invite Sports söker en entreprenör som vill öka kunskapen omkring Cup Manager och Superinvite i Sverige. Vi har redan en solid kundbas, som vi tror kan bli större med en duktig relationssäljare på plats! Utbudet på turneringar och klubbar i Sverige är stort och vi ser stora möjligheter till att ta fler marknadsandelar med våra produkter.</p>

        <img src={cm01_img} alt="" className={styles.img01_01} /><h2>Om Invite</h2>
        
        <p>
        Invite Sports är resultat av en sammanslagning mellan norska Superinvite och svenska Cup Manager. Båda lösningarna har funnits sedan 2012, är lönsamma och har många nöjda kunder! Superinvite används för insamling av avgifter för t.ex. träningar och fotbollsläger. Cup Manager är en helhetslösning för idrottsturneringar som hanterar allt från anmälningar av lag, betalningar, boende, schema, resultat, hemsida och appar.  I dag har vi fler än 1100 aktiva föreningar i vårt kundregister.  
        </p>

        <img src={cm01_img} alt="" className={styles.img01_02} />

        <p>
          Vi har idag säljare i Norge och Danmark och ett kontor i Lund med utvecklare och duktiga supportmedarbetare som hanterar support för båda systemen på alla språk.
        </p>

        <h2>Om tjänsten</h2>
        <p>
        I tjänsten som Country Sales Manager rapporterar du primärt till vår försäljningschef, men har också nära samarbete med kundsupport-avdelningen.
        </p>

        
        <p>
        Du arbetar antingen hemifrån eller från kontoret i Lund där vi har ljusa och fräscha lokaler. Här kan du träffa kollegor, spela en match fussball eller stänga dörren om dig och fokusera. Vi tror att ett par dagar på plats i Lund i början hjälper dig att komma igång även om du sedan kan arbeta varifrån du vill, då du kommer att arbeta med kunder i hela Sverige med fokus på digitala möten. 
        </p>

        <img src={cm02_img} alt="" className={styles.img02_01} />

        <h2>Om dig</h2>
        <p>
        Som person är du en säljare ut i fingerspetsarna, du älskar att prata med människor. Du triggas av resultat och söker alltid lösningar i alla situationer. Du är en relationsbyggare som är intresserad av att lära känna dina kunder och deras vardag. Du tar initiativ och är självgående.
        </p>
        <p>
        Vi ser gärna att du arbetat med försäljning tidigare. Har du dessutom erfarenhet av föreningar och/eller turneringar, är det ett stort plus. Du har fokus på detaljer och vet att det lönar sig att ha is i magen.  
        </p>

        <h2>Huvudsakliga arbetsuppgifter</h2>
        <ul>
          <li>Försäljning och marknadsföring av Invites produkter med primärt fokus på Cup Manager och Superinvite.</li>
          <li><img src={cm03_img} alt="" className={styles.img03_01} />Merförsäljning på existerande kunder.</li>
          <li>Nyförsäljning på kunder i hela Sverige.</li>
        </ul>

        <img src={cm03_img} alt="" className={styles.img03_02} />

        <h2>Vi erbjuder dig</h2>
        <ul>
          <li>En attraktiv provisionslösning som kommer stå för merparten av din lön.</li>
          <li>Fast grundlön efter överenskommelse.</li>
          <li>Mobil och abonnemang.</li>
          <li>Frihet under ansvar.</li>
          <li>Möjligheter att utvecklas och avancera i företaget.</li> 
          <li>En arbetsgivare där vägen till beslut är kort och ingenting är omöjligt.</li>
        </ul>

        <h2>Förmåner</h2>

        <div className={styles.perks_grid}>
          <div>
            <h3>
              <span>
              <i className="fa-regular fa-wave-pulse"></i>
              </span>
              <span>Friskvårdsbidrag</span>
            </h3>
            <p>Upp till 5000 kronor kan du spendera på golf, gym, padel, klättring - eller vad du gillar att göra för att främja hälsan.</p>
          </div>
          <div>
            <h3>
              <span>
                <i className="fa-regular fa-martini-glass-citrus"></i>
              </span>
              <span>Afterwork</span>
            </h3>
            <p>En gång i månaden bjuder Invite på afterwork. Det kan bli galna aktiviteter eller lite mys i någon trevlig lounge.</p>
          </div>
          <div className={styles.grid_polaroid}>
            <img src={cm04_img} alt="" className={styles.img04_01} />
          </div>
          <div>
            <h3>
              <span>
                <i className="fa-regular fa-house-laptop"></i>
              </span>
              <span>Eget rum/arbeta hemma</span>
            </h3>
            <p>Du är fri att välja om du vill jobba hemifrån eller komma till kontoret när du kommit igång.</p>
          </div>
          <div>
            <h3>
              <span>
              <i className="fa-regular fa-apple-whole"></i>
              </span>
              <span>Fruktkorg</span>
            </h3>
            <p>Varje måndag får vi en korg levererad med färsk frukt så att du får i dig lite extra vitaminer.</p>
          </div>
          <div>
            <h3>
              <span>
                <i className="fa-regular fa-mug-saucer"></i>
              </span>
              <span>Finkaffe</span>
            </h3>
            <p>Sandby Kafferosteri är vår leverantör av kaffe. Vi vågar nästan säga att vi brygger stans bästa kontorskaffe i vår Moccamaster.</p>
          </div>
          <div>
            <h3>
              <span>
              <i className="fa-regular fa-dog"></i>
              </span>
              <span>Ta med hunden</span>
            </h3>
            <p>
              Har du hund? Då behöver du inte tänka på hunddagis, pensionat, etc. Ta med den hit! Hundar finns här från och till.
            </p>
          </div>
        </div>

        <h2>Varaktighet, arbetstid</h2>
        <p>Tjänsten är en tillsvidareanställning på 100%. Tillträde omgående eller enligt överenskommelse. </p>

        <h2>Ansökan</h2>
        <p>Ansökan, eller eventuella frågor, skickas till <a href="mailto:petronella+sales@cupmanager.net">petronella+sales@cupmanager.net</a></p>

      </div>

      
    </div>
  </div>
  </>
}