import styles from './Children.module.css';
import si from '../../img/si.jpg';
import cm from '../../img/cm.jpg';
import logo_superinvite from '../../img/logo_superinvite.svg';
import logo_cupmanager from '../../img/logo_cupmanager.svg';
import logo_superinvitar from '../../img/logo_superinvitar.svg';
import MyLink from '../MyLink/MyLink';
import classNames from 'classnames';

export default function Children () {
  return <div className={styles.children} id="children01-id">
    <div className={styles.spotLine}>
      <div className={styles.backgroundContainer}>
        <div className={styles.background} style={{backgroundImage: `url(${si})`}}>
        </div>
      </div>
      <div className={styles.aboutChildren}>
        <article>
          <h3 className={styles.s_header}>
          <img src={logo_superinvite} alt="SuperInvite" className={classNames(styles.logo, styles.superinvite)}/>
          </h3>
          <p>Superinvite is a fully equipped payment solution, designed to fit all the needs of any sports club. Superinvite covers regular payments, recurrent payment, invitation lists, as well as attendance tracking. It is easy to use, and you can be up and running within minutes.</p>
          <MyLink url="https://superinvite.com/" text="SuperInvite.com" />
        </article>
      </div>
    </div>
    <div className={styles.spotLine}>
      <div className={styles.aboutChildren}>
        <article>
          <h3>
            <img src={logo_cupmanager} alt="Cup Manager" className={styles.logo}/>
          </h3>
          <p>Cup Manager is used by more than 900 tournaments across 18 different countries. It offers an all-in-one system for both small and big tournaments, such as Gothia Cup, Dana Cup and Norway Cup. Create game schedules and track scores, but also handle attendees signing up for the tournament as well as a tournament home page and app. </p>
          <MyLink url="https://cupmanager.net/" text="CupManager.net" />
        </article>
      </div>
      <div className={styles.backgroundContainer}>
        <div className={styles.background} style={{backgroundImage: `url(${cm})`}}>
        </div>
      </div>
    </div>
  </div>
}