import Modal from 'react-modal';

import styles from './Employees.module.css';

import anton from '../../img/employees/anton.webp'
import dag from '../../img/employees/dag.webp'
import emma from '../../img/employees/emma.webp'
import erik from '../../img/employees/erik.webp'
import havard from '../../img/employees/havard.webp'
import jimmy from '../../img/employees/jimmy.webp'
import joakim from '../../img/employees/joakim.webp'
import max from '../../img/employees/max.webp'
import petronella from '../../img/employees/petronella.webp'
import rebecka from '../../img/employees/rebecka.webp'
import stefan from '../../img/employees/stefan.webp'
import jessika from '../../img/employees/jessika.webp'
import noPhoto from '../../img/employees/no_photo.png'
import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';


const employees = [
  {
    fullName: 'Anton Spanne',
    jobTitle: 'Developer',
    imgSrc: anton,
    info: "Anton has a PhD in neurophysiology, but has also studied computer science and mathematics. He is the brain behind our automatic functions and responsible for development issues. When he's not researching or developing Cup Manager, he's feeding his pigs and building his house.",
    contacts: {
      email: 'anton@cupmanager.net',
    }
  },
  {
    fullName: 'Rebecka Sjöholm',
    jobTitle: 'Support',
    imgSrc: rebecka,
    info: "Rebecka har arbetat med kundservice genom hela sitt arbetsliv och har en omhändertagande och trevlig natur som gör henne enkel att arbeta med. Rebecka har alltid haft ett stort intresse för djur och engagerar sig gärna i olika djurfrågor. Hon är lösningsorienterad och hjälper gärna dig utefter just dina behov.",
    contacts: {
      email: 'support@cupmanager.net',
      phone: '+46 46-525 80'
    }
  },
  {
    fullName: 'Dag Henrik Bratile',
    jobTitle: 'CEO',
    imgSrc: dag,
    info: "Dag Henrik has worked with digital solutions since 2004 and has a good understanding of what users want. Contact Dag Henrik if you have technical questions about Superinvite, would like an overview of our services or are interested in any other collaboration.",
    contacts: {
      email: 'dag@superinvite.no',
      phone: '+47 482 54 975'
    }
  },
  {
    fullName: 'Emma Sjöstrand',
    jobTitle: 'Support',
    imgSrc: emma,
    info: "Emma has worked for a long time with customer relations and support, has a great technical interest and is an expert in websites. She always focuses on solutions and your needs as a customer!",
    contacts: {
      email: 'support@cupmanager.net',
      phone: '+46 46-525 80'
    }
  },
  {
    fullName: 'Erik Frantzen',
    jobTitle: 'Marketing director',
    imgSrc: erik,
    info: "Erik has over 10 years' experience with the operation of sports clubs, handling membership and training fees, sports schools, football after school, tournaments and other activities. Erik also has over 10 years of experience from helping all our clubs in our systems.",
    contacts: {
      email: 'erik@superinvite.no',
      phone: '+47 924 45 658'
    }
  },
  {
    fullName: 'Håvard Nordlie',
    jobTitle: 'Country manager Norway',
    imgSrc: havard,
    info: "Håvard has extensive experience in the administration of clubs, and knows our products well. If you want an informal chat about what we can help you with, you can contact Håvard.",
    contacts: {
      email: 'havard@superinvite.no',
      phone: '+47 414 53 151'
    }
  },
  {
    fullName: 'Jimmy Carlström',
    jobTitle: 'Country manager Sweden',
    imgSrc: jimmy,
    info: "Jimmy has worked in sales all his life, and has a huge interest in sports at the bottom. He has played everything that can be played with a ball, and now coaches youth teams in handball. Jimmy is happy to tell you about the possibilities in Cup Manager for your particular tournament!",
    contacts: {
      email: 'jimmy@cupmanager.net',
      phone: '+46 73 766 18 80'
    }
  },
  {
    fullName: 'Joakim Borg',
    jobTitle: 'Front-end developer & designer',
    imgSrc: joakim,
    info: "Joakim has extensive experience as a designer and likes to design and develop new user-friendly and accessible solutions. Here he mainly designs and develops our various web apps, old as new. He is the current golf champion in the office.",
    contacts: {
      email: 'joakim@cupmanager.net'
    }
  },
  {
    fullName: 'Max Martinsson',
    jobTitle: 'Developer',
    imgSrc: max,
    info: "Max has been programming since childhood and has studied cognitive science at university. In addition to many side quests, he worked as a developer before moving on to work full-time on Cup Manager. Max is the best in the office at fussball.",
    contacts: {
      email: 'max@cupmanager.net'
    }
  },
  {
    fullName: 'Petronella Liljedahl', 
    jobTitle: 'Team leader support',
    imgSrc: petronella,
    info: "Petronella has worked in the hotel industry with sales and customer service. She has good technical knowledge, and always focuses on helping you as a customer.",
    contacts: {
      email: 'support@cupmanager.net',
      phone: '+46 46-525 80'
    }
  },
  {
    fullName: 'Stefan Evertsson',
    jobTitle: 'Finance & Administration',
    imgSrc: stefan,
    info: "Stefan has worked in the IT industry since the late 70s. He has also worked in Lugi Handball where he administered the Lunda Games for 5 years. He plays golf and has made two \"Hole in one\" on the same hole.",
    contacts: {
      email: 'stefan@cupmanager.net'
    }
  }
  ,
  {
    fullName: 'Jessika Sjöholm',
    jobTitle: 'Support',
    imgSrc: jessika,
    info: "Jessika has spent her entire career working in sales and customer service. With a keen eye for detail and a great sense of intuition, she assists you in navigating Cup Manager and answers your questions. In her free time, she plays GW2 and streams esports.",
    contacts: {
      email: 'support@cupmanager.net'
    }
  }
]

export default function Employees ({setModalVisible} : {setModalVisible: (val:boolean) => void}) {

  const _employees = employees.sort((a, b) => { // Just sort by name
    if (a.fullName < b.fullName) {
      return -1;
    }
    if (a.fullName > b.fullName) {
      return 1;
    }
    return 0;
  })
  

  const closeModal = () => {
    setSelectedEmployeeId(undefined)
  }

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<undefined | string>(undefined);
  const selectedEmployeeData = selectedEmployeeId ? _employees.find(employee => employee.fullName === selectedEmployeeId) : undefined;

  const changeEmployee = useCallback((dir: 'prev' | 'next') => {
    const employeesLength = _employees.length;
    const employeeIndex = _employees.findIndex(e => e.fullName === selectedEmployeeId)

    if (dir === 'next') {
      if(employeeIndex === employeesLength - 1) {
        setSelectedEmployeeId(_employees[0].fullName)
      } else {
        setSelectedEmployeeId(_employees[employeeIndex + 1].fullName)
      }
    }

    if (dir === 'prev') {
      if(employeeIndex === 0) {
        setSelectedEmployeeId(_employees[employees.length - 1].fullName)
      } else {
        setSelectedEmployeeId(_employees[employeeIndex - 1].fullName)
      }
    }
  }, [_employees, selectedEmployeeId]);



  useEffect(() => {
    if(selectedEmployeeId) {
      setModalVisible(true)
    } else {
      setModalVisible(false)
    }

  }, [selectedEmployeeId, setModalVisible]);



  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {

      if (selectedEmployeeId) {
        if(e.key === 'ArrowRight') {
          setTimeout(() => {
            changeEmployee('next');
          }, 0)
          
          setDIrection('right');
          
        }
    
        if(e.key === 'ArrowLeft') {
          setTimeout(() => {
            changeEmployee('prev');
          }, 0)
          
          setDIrection('left');
          
        }
      }
    }

    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    }
  }, [changeEmployee, selectedEmployeeId])

  Modal.setAppElement('body');

  const [direction, setDIrection] = useState<'left' | 'right'>('right')
  const cssTransitionTimeout = 500;
  

  return <div className={styles.children} id="employees-id">
    <h3>The Team of Invite Sports</h3>
    <div className={styles.employees}>
      {_employees.map((employee, i) => <Employee employee={employee} setSelectedEmployee={setSelectedEmployeeId} key={i} />)}
    </div>

    <Modal
      isOpen={!!selectedEmployeeId}
      onRequestClose={closeModal}
      className={styles.modal}
      closeTimeoutMS={200}
    >
      <button className={styles.close} onClick={() => closeModal()}>
        <i className="fa-regular fa-xmark"></i> <span>Close</span>
      </button>

      <button className={classNames(styles.arrow_btn, styles.left)} aria-label='Previous employee' onClick={() => {
        changeEmployee('prev');
        setDIrection('left');
      }}>
        <i className="fa-light fa-angle-left"></i>
      </button>
      <button className={classNames(styles.arrow_btn, styles.right)} aria-label='Next employee' onClick={() => {
        changeEmployee('next');
        setDIrection('right');
      }}>
        <i className="fa-light fa-angle-right"></i>
      </button>
      <div className={`slide_employee_${direction}`} style={{'--cssTransitionTimeout': cssTransitionTimeout+'ms'} as any}>
        <TransitionGroup className={styles.modal_employee}>
          <CSSTransition
            key={selectedEmployeeId}
            timeout={cssTransitionTimeout}
            className={styles.slider}
          >
            <div>
              <img src={selectedEmployeeData?.imgSrc} alt={selectedEmployeeData?.fullName}/>
              <div className={styles.employee_info}>
                <h2>
                  {selectedEmployeeData?.fullName}
                </h2>
                <span className={styles.title}>{selectedEmployeeData?.jobTitle}</span>
                <p>{selectedEmployeeData?.info}</p>
                {selectedEmployeeData?.contacts && <div className={styles.contacts}>
                  {selectedEmployeeData.contacts.email && <span>
                    <i className="fa-solid fa-envelope fa"></i> {selectedEmployeeData.contacts.email}
                  </span>}
                  {selectedEmployeeData.contacts.phone && <span>
                    <i className="fa-solid fa-phone"></i> {selectedEmployeeData.contacts.phone}
                  </span>}
                </div>}
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </Modal>
  </div>
}

type employeeProps = {
  employee: {
    fullName: string,
    jobTitle: string,
    imgSrc: string,
    info: string,
    contacts?: {
      email?: string,
      phone?: string
    }
  },
  setSelectedEmployee: (id:string) => void
}

function Employee ({employee, setSelectedEmployee} : employeeProps) {

  const lastSpace = employee.fullName.indexOf(' ');
  const firstName = employee.fullName.substring(0, lastSpace);
  const lastName = employee.fullName.substring(lastSpace, employee.fullName.length + 1)

  return <button 
    className={styles.info} 
    onClick={() => setSelectedEmployee(employee.fullName)}
  >
    <img src={employee.imgSrc} alt={employee.fullName}/>
    <h3>{firstName} <span className={styles.last_name}>{lastName}</span></h3>
    <span>{employee.jobTitle}</span>
  </button>
}