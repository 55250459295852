import styles from './JobPosHead.module.css';

import logo_cupmanager from '../../img/logo_cupmanager.svg';
import logo_superinvite from '../../img/logo_superinvite.svg';
import logo_cupinsight from '../../img/logo_cupinsight.svg';
import logo_cupinvite from '../../img/logo_cupinvite.svg';
import logo_invite from '../../img/invite-white.svg';

export default function JobPosHead () {


  return <div className={styles.outerLogos}>
  <div className={styles.invite}>
    <a href="http://www.invitesports.com">
      <img src={logo_invite} alt="" />
    </a>
  </div>
  <div className={styles.logos}>
    <span>
      <a href="https://superinvite.com/">
        <img src={logo_superinvite} alt="" className={styles.superInvite} />
      </a>
    </span>
    <span>
      <a href="https://cupmanager.net/">
        <img src={logo_cupmanager} alt="" className={styles.cupManager} />
      </a>
    </span>
    <span>
      <a href="https://cupinsight.cups.nu/">
        <img src={logo_cupinsight} alt="" className={styles.visualFunding} />
      </a>
    </span>
    <span>
      <a href="https://cupinvite.com/">
        <img src={logo_cupinvite} alt="" className={styles.cupInvite} />
      </a>
    </span>
  </div>
</div>
}