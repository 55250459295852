import styles from './MyLink.module.css';

type props = {
  url: string,
  text: string
}

export default function MyLink ({url, text} : props) {
  return <a href={url} className={styles.myLink} target="_blank" rel='noreferrer'>
    <i className="fal fa-external-link-square"></i> <span>{text}</span>
  </a>
}