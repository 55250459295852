import styles from './CustomerServiceWorker.module.css';

import cm01_img from '../../img/cup_manager_01_variant.webp';
import cm02_img from '../../img/cup_manager_02_variant.webp';
import cm03_img from '../../img/cup_manager_03_variant.webp';
import cm04_img from '../../img/cup_manager_04_variant.webp';
import JobPosHead from '../JobPosHead/JobPosHead';

export default function CustomerServiceWorker () {
  

  return <>
  <JobPosHead />
  <div className={styles.children} id="invite-id">
    <div className={styles.innerWrapper}>
      <div className={styles.info}>
        <span className={styles.date}>Publicerat: 2023-12-18</span>

        <h1>Självständig kundservice-medarbetare till växande sportbolag</h1>

        <p className={styles.preamble}>Invite är inne i en expansiv period och söker därför efter en engagerad och serviceinriktad medarbetare som i första hand skall arbeta heltid med kundhantering och support till nya och existerande kunder.</p>

        <img src={cm01_img} alt="" className={styles.img01_01} /><h2>Om Invite</h2>
        
        <p>
          Invite är ett spännande företag med stora tillväxtambitioner. Vi har avdelningar i Oslo och Lund med kunder runt om i världen. I produktportföljen finns bland annat Cup Manager och Superinvite - verktyg som hanterar registreringar, betalningar och rapportering till idrottsrörelsens olika behov.
        </p>

        <img src={cm01_img} alt="" className={styles.img01_02} />

        <p>
          Cup Manager används av nästan tusen idrottsturneringar i Sverige, Norge, Danmark och resten av världen för att administrera allt från anmälningar till spelschema och resultat. Superinvite är en ledande lösning i Norge för att samla in avgifter för t.ex. träningar och fotbollsläger.
        </p>

        <h2>Om supporten på Invite</h2>
        <p>
          Vi har en mycket hög ambitionsnivå när det gäller vår support. Vi svarar inte bara på frågor enligt ett manus utan har också en rådgivande roll för våra kunder. Det handlar om att förstå kundens önskemål och behov, och omvandla det till en passande konfiguration i systemet eller att rekommendera en bra arbetsprocess. De flesta av våra kunder känner vi vid namn och vi tror att goda relationer leder till förtroende och bra affärer.
        </p>

        <h2>Huvudsakliga arbetsuppgifter</h2>
        <ul>
          <li>Ge first line-support på telefon, chatt och e-post.</li>
          <li>Hålla bokade videomöten med kunder för mer ingående rådgivning, problemlösning och hjälp, exempelvis vid schemaläggning av turneringen.</li>
          <li>Hålla videomöten med potentiella kunder för att visa upp systemen.</li>
          <li>Aktiv uppföljning av turneringar under deras livscykel - anmälan, spelschema, genomförande, som exempel.</li>
        </ul>

        <img src={cm02_img} alt="" className={styles.img02_01} />
        <p>
          Vi söker efter dig som är självständig, organiserad, noggrann och engagerad. Du är serviceminded och har en förmåga att sätta dig in i kundens situation. Du är en god kommunikatör som kan få fram ditt budskap på ett effektivt sätt och bli förstådd av mottagaren.
        </p>
        <p>
          Du tycker precis som vi att styrkan ligger i detaljerna och har förmågan och viljan att göra det där lilla extra i varje kundkommunikation. Du är duktig på att fånga upp, dokumentera och strukturera den feedback du får i din kontakt med kunderna.
        </p> 
        <p>
          Vi arbetar med frihet under ansvar, så du ska trivas med att fatta egna beslut och ta egna initiativ i din arbetsdag.
        </p>


        <h2>För att passa hos oss tror vi att du</h2>

        <ul>
          <li>Har några års erfarenhet av arbete med kunder eller service sedan tidigare.</li>
          <li>Älskar att arbeta med människor och tycker det är roligt att leverera god service.</li>
          <li>Är bekväm med att arbeta i olika IT-system.</li>
          <li>Trivs i en miljö under förändring där du själv kan påverka riktningar och rutiner.</li>
          <li>Kommunicerar obehindrat på svenska och engelska i både tal och skrift. Du har förmåga att kunna läsa och förstå danska och gärna spanska.</li>
          <li>Tar egna initiativ för att utvecklas och lära dig mer.</li>
        </ul>

        <img src={cm03_img} alt="" className={styles.img03_02} />

        <h2>Detta kan vi erbjuda</h2>
        <ul>
          <li>Ung och dynamisk miljö.</li>
          <li>Utvecklingsmöjligheter.</li>
          <li>En vardag där vi tänker utanför boxen.</li>
          <li>Frihet under ansvar.</li>
          <li>Spännande och varierande arbetsuppgifter.</li> 
          <li>Fräscha kontorslokaler med parkering och närhet till buss och spårvagn.</li>
          <li>Ett mysigt café strax utanför entrédörren och två riktigt bra lunchrestauranger på nära avstånd.</li>
          <li>Efter upplärningsperiod finns det möjlighet att delvis arbeta hemifrån. På måndagar träffas vi alla på kontoret.</li>
          <li>Trygg och fast anställning på ett företag med solid ekonomi.</li>
        </ul>

        <h2>Förmåner</h2>

        <div className={styles.perks_grid}>
          <div>
            <h3>
              <span>
              <i className="fa-regular fa-wave-pulse"></i>
              </span>
              <span>Friskvårdsbidrag</span>
            </h3>
            <p>Upp till 5000 kronor kan du spendera på golf, gym, padel, klättring - eller vad du gillar att göra för att främja hälsan.</p>
          </div>
          <div>
            <h3>
              <span>
                <i className="fa-regular fa-martini-glass-citrus"></i>
              </span>
              <span>Afterwork</span>
            </h3>
            <p>En gång i månaden bjuder Invite på afterwork. Det kan bli galna aktiviteter eller lite mys i någon trevlig lounge.</p>
          </div>
          <div className={styles.grid_polaroid}>
            <img src={cm04_img} alt="" className={styles.img04_01} />
          </div>
          <div>
            <h3>
              <span>
                <i className="fa-regular fa-house-laptop"></i>
              </span>
              <span>Eget rum/arbeta hemma</span>
            </h3>
            <p>Du får ett eget rum på kontoret så att du kan stänga om dig, men du kan också arbeta en del hemifrån om du behöver det.</p>
          </div>
          <div>
            <h3>
              <span>
              <i className="fa-regular fa-apple-whole"></i>
              </span>
              <span>Fruktkorg</span>
            </h3>
            <p>Varje måndag får vi en korg levererad med färsk frukt så att du får i dig lite extra vitaminer.</p>
          </div>
          <div>
            <h3>
              <span>
                <i className="fa-regular fa-mug-saucer"></i>
              </span>
              <span>Finkaffe</span>
            </h3>
            <p>Sandby Kafferosteri är vår leverantör av kaffe. Vi vågar nästan säga att vi brygger stans bästa kontorskaffe i vår Moccamaster.</p>
          </div>
          <div>
            <h3>
              <span>
              <i className="fa-regular fa-dog"></i>
              </span>
              <span>Ta med hunden</span>
            </h3>
            <p>
              Har du hund? Då behöver du inte tänka på hunddagis, pensionat, etc. Ta med den hit! Hundar finns här från och till.
            </p>
          </div>
        </div>

        {/*<ul>
          <li>Fruktkorg på måndagar</li>
          <li>Fussballpaus på eftermiddagarna</li>
          <li>Eget rum på kontoret</li>
          <li>Jobba hemifrån</li>
          <li>Café i samma hus och riktigt bra restauranger i närheten</li>
          <li>AW en gång i månaden</li>
          <li>Pannkakslunch på torsdagar</li>
          <li>Friskvårdsbidrag</li>
          <li>Hundvänlig arbetsplats</li>
        </ul>*/}

        <h2>Varaktighet, arbetstid</h2>
        <p>Tjänsten är en tillsvidareanställning på 100% med placering på vårt kontor vid Mobilvägen 10 i Lund. Tillträde enligt överenskommelse.</p>

        <h2>Ansökan</h2>
        <p>Ansökan, eller eventuella frågor, skickas till <a href="mailto:petronella@cupmanager.net">petronella@cupmanager.net</a></p>

      </div>

      
    </div>
  </div>
  </>
}